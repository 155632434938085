import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
// #fake-start#
import { FakeAPIService } from './_fake/fake-api.service';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
// #fake-end#
import { defineLocale } from "ngx-bootstrap/chronos";
import { thBeLocale } from "ngx-bootstrap/locale";
import { AuthService } from './services/auth.service';
import { BasicSettingComponent } from './pages/basic-setting/basic-setting.component';
import { LandComponent } from './pages/land/land.component';
import { BuildingComponent } from './pages/building/building.component';
import { MaterialComponent } from './pages/material/material.component';
import { AssetComponent } from './pages/asset/asset.component';
import { VehicalComponent } from './pages/vehical/vehical.component';
import { PocComponent } from './pages/poc/poc.component';
defineLocale("th-be", thBeLocale);

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      //@ts-ignore
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  decimal: ".",
  precision: 2,
  prefix: "$ ",
  suffix: "",
  thousands: ",",
  nullable: true,
};

@NgModule({
  declarations: [AppComponent, BasicSettingComponent, LandComponent, BuildingComponent, MaterialComponent, AssetComponent, VehicalComponent, PocComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    // #fake-start#
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
        passThruUnknownUrl: true,
        dataEncapsulation: false,
      })
      : [],
    // #fake-end#
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    SweetAlert2Module.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circle,
      size: '50px',
      backdropBackgroundColour: 'rgba(0,0,0,0.7)',
      backdropBorderRadius: '0px',
      fullScreenBackdrop: true,
      primaryColour: '#595BDB',
      secondaryColour: '#757FDF',
      tertiaryColour: '#939DE4'
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],

    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
